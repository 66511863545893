import React, { useState, useEffect, useContext } from 'react';
import Gallery from 'react-photo-gallery';
import { Modal } from 'antd';
import Component from 'constants/Component';
import { GetData } from 'utils/fetchData';
import { LocalizationContext } from 'context/LangChange';
import useShare from 'utils/useShare';
import Aos from 'aos';


const CustomGallery = () => {
    let { isLoading, setIsLoading, header } = useShare()

    const [visible, setVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [photos, setPhotos] = useState([]); // State to store fetched photos
    const { isLang } = useContext(LocalizationContext); // Assuming context usage for language

    const url = `${process.env.REACT_APP_API_URL}/galleries`;

    const fetchData = async () => {
        GetData(url, header) // Assume header is needed
            .then(data => {
                console.log(data.data);
                if (data.status === 200) {
                    // Transform and set photos data
                    const transformedPhotos = data.data?.data.map((img, index) => {
                        const dimensions = getDimensions(index); // Dynamically determine dimensions
                        return {
                            src: img.image,
                            width: dimensions.width,
                            height: dimensions.height
                        };
                    });
                    setPhotos(transformedPhotos);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    // Define a function to dynamically determine width and height
    const getDimensions = (index) => {
        // Example: Alternate dimensions based on whether index is odd or even
        if (index % 2 === 0) {
            return { width: 4, height: 4 };
        } else {
            return { width: 4, height: 4 };
        }
    };

    const handleOpen = (event, { photo }) => {
        setCurrentImage(photo);
        setVisible(true);
    };

    const handleClose = () => {
        setVisible(false);
        setCurrentImage(null);
    };

    useEffect(() => {
        fetchData();
        window.scrollBy(0, -window.pageYOffset);
        Aos.init({ duration: 1500 });
    }, [isLang]); // Dependency on language context if necessary

    if (isLoading) {
        return <Component.Loader />;
    }

    return (
        <div className='app_gallerys_pages'>
            <div style={{ marginTop: '100px', padding: '30px' }} className='mb-8'>
                <Gallery photos={photos} onClick={handleOpen} />
            </div>
            <Modal visible={visible} footer={null} onCancel={handleClose} centered zIndex={9999}>
                {currentImage && <img src={currentImage.src} alt="" style={{ width: '100%' }} />}
            </Modal>
            <Component.Footer />
        </div>
    );
};

export default CustomGallery;
