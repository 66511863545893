import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './navbar.scss';
import routes from './routes';
import { ApiContext } from 'context/FatchApi';
const languages = [
    {
        code: 'fr',
        name: 'Français',
        dir: 'ltr',
        country_code: 'fr',
    },
    {
        code: 'en',
        name: 'English',
        dir: 'ltr',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
]
const NavBar = () => {
    const { t } = useTranslation()
  let { settings } = useContext(ApiContext);

    const [activeLink, setActiveLink] = useState(0);
    const location = useLocation();
    let links = location.pathname;
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

    let { isLang, setIsLang, LogOut } = useContext(LocalizationContext);
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
    }, [currentLanguage, t])
    const handleLanguageChange = (selectedLanguageCode) => {
        setIsLang(selectedLanguageCode);
        if (selectedLanguageCode === 'en') {
            document.body.dir = 'ltr';
        } else {
            document.body.dir = 'rtl';
        }
        i18next.changeLanguage(selectedLanguageCode);
    };
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);





    const isHomeOrTwining = links === '/' || links === '/services';
    const linkColor = isHomeOrTwining ? 'text-light' : 'text-dark';
    const linkActive = (path) => links === path ? 'active' : linkColor;
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleLinkClick = (index) => {
        setActiveLink(index);
        setOpen(true);
    };
    return (
        <div className='app__navbar '  >
            <Container>
                <Navbar
                    dir='ltr'
                    fixed={scrollPosition > 600 ? 'top' : 'top'}
                    collapseOnSelect
                    expand="sm"
                    className={` ${links === '/' ? scrollPosition > 600 ? 'scroll shadow navbar-navOnthorPAge   ' : '' : scrollPosition > 0 ? 'scroll navbar-navOnthorPAge   ' : ''}  ${  scrollPosition > 600 ? '  shadow- ' : ''  }  `}
                >
                    <div className="flex justify-content-between    align-items-center  w-100 container-fluid  "
                        dir={cookies.get('i18next') === 'en' ? 'rtl' : 'ltr'}
                    >
                        <div className="nav_logo_sm">
                            <div className="nav_links flex justify-content-between nav_bar_am ">
                                <div className="sidebar_sm" >
                                    <Nav>
                                        <Nav.Item className='d-flex justify-content-between align-items-center gap-5'>
                                            <div className={`dropdown language-select-root ${isLang === 'ar' ? 'dropdown_dirAr' : 'dropdown_dir'}`}>
                                                <div className="change-lang" onClick={() => handleLanguageChange(isLang === 'en' ? 'ar' : 'en')}>
                                                    <Tooltip placement={t('placement_Tooltip')} title={<span style={{ color: '#000' }}>{t('lang')}</span>} color={'#fff'}>
                                                        <img src={isLang === 'en' ? img.ArLang : img.EnLang} alt="Language icon" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <Link to='/contact-us' className='ml-4 app__menu-left'>
                                                <div
                                                    className='app__menu-talk'
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}>
                                                    {t('nav_contact')}
                                                    <span className="spinner-grow spinner-grow-sm" role="status"></span>
                                                </div>
                                            </Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Nav className={`${cookies.get('i18next') === "en" ? 'dropdown_en' : 'dropdown_ar'}`}>
                                        <Nav.Link as={Link} to='/contact-us' className={linkActive('/contact-us')}>{t('nav_connect')}</Nav.Link>
                                        <Nav.Link as={Link} to='/gallery' className={linkActive('/gallery')}>{t('nav_gallery')}</Nav.Link>
                                        {/* <Nav.Link as={Link} to='/services' className={linkActive('/services')}>{t('nav_clients')}</Nav.Link> */}
                                        <Nav.Link as={Link} to='/products' className={linkActive('/products')}>{t('nav_service')}</Nav.Link>
                                        <Nav.Link as={Link} to='/about' className={linkActive('/about')}>{t('nav_about')}</Nav.Link>
                                        <Nav.Link as={Link} to='/' className={linkActive('/')}>{t('nav_home')}</Nav.Link>
                                    </Nav>
                                    <div className="nav_logo_lg flex  justifyalign-content-center  align-items-center">
                                        {scrollPosition > 600 ? <Link to={'/'}><img src={img.logo} alt="" /> </Link> :
                                            <>
                                                {(links === '/' || links === '/services') ? <Link to={'/'}><img src={img.logo_light} alt="icon " /> </Link> : <Link to={'/'}><img src={img.logo} alt="" /></Link>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nav_logo_sidebar     flex justify-content-between  align-items-center">
                            <div className="sidebar_lg">
                                <Button shape="circle" size='large' icon={<MenuOutlined size='small' />} onClick={showDrawer} />
                            </div>
                            <img src={img.logo} alt='logo nagro' />
                        </div>



                        <Drawer
                            title={<img src={img.logo_light} alt="logo" width={150} />}
                            placement={cookies.get('i18next') !== "en" ? "right" : "left"}
                            closable={true}
                            onClose={onClose}
                            open={open}
                            key={placement}
                            className='bg_sidebar view_sidebar_home-page'
                            closeIcon={true}
                            footer={<div className='app__footer'>
                                <div className={`social  `}>
                                <a
                                        href={settings?.twitter1}
                                        target='_blank' rel="noreferrer"
                                        className='icons cursor-pointer bg-black-alpha-40'
                                    >
                                        <Icons.Twitter color={'#F68B1F'} />
                                    </a>

                                    <a
                                        href={settings?.tiktok1}
                                        target='_blank' rel="noreferrer"
                                        className='icons'

                                    >
                                        <Icons.Facebook color={'#F68B1F'} className='facebook' />
                                    </a>

                                    <a
                                        href={settings?.instagram1}
                                        target='_blank' rel="noreferrer"
                                        className='icons'

                                    >
                                        <Icons.Instagram color={'#F68B1F'} />
                                    </a>
                                    <a
                                        href={settings?.twitter2}
                                        target='_blank' rel="noreferrer"
                                        className='icons cursor-pointer bg-black-alpha-40'
                                    >
                                        <Icons.Twitter color={'#F68B1F'} />
                                    </a>

                                    <a
                                        href={settings?.tiktok2}
                                        target='_blank' rel="noreferrer"
                                        className='icons'

                                    >
                                        <Icons.Facebook color={'#F68B1F'} className='facebook' />
                                    </a>

                                    <a
                                        href={settings?.instagram2}
                                        target='_blank' rel="noreferrer"
                                        className='icons'

                                    >
                                        <Icons.Instagram color={'#F68B1F'} />
                                    </a>

                                
                                </div>
                            </div>}
                        >
                            <div className="navbar_sm_page">
                                <div className="d-flex flex-column" >
                                    <section className={'routes'} >
                                        {routes?.map((root, i) => {
                                            return (
                                                <div key={i}>
                                                    <Link
                                                        to={root.path}
                                                        className={`link ${activeLink === i ? 'active' : ''}  ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                                        onClick={() => {
                                                            handleLinkClick(i)
                                                            onClose()
                                                        }}
                                                    >
                                                        <div className={`icon  ${activeLink === i ? 'active' : ''}`} id={root.name} >
                                                            {root.icon}
                                                        </div>
                                                        <div className="link_text">{isLang === "en" ? root.nameEn : root.nameAr}</div>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </section>
                                </div>
                                <div className="change_lang-sidebar " dir='rtl '>
                                    {
                                        isLang === "en" ?
                                            <div
                                                className='app__menu-talk lang_text cursor-pointer '
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.8 }}
                                                onClick={() => {
                                                    handleLanguageChange('ar')
                                                    i18next.changeLanguage('ar')
                                                    handleLinkClick()
                                                }}

                                            >
                                                <span className=''>  عربيه</span>
                                                <img src={img.ArLang} alt="images" className='rounded-circle' />
                                            </div> :
                                            <div
                                                className='app__menu-talk cursor-pointer '
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.8 }}
                                                onClick={() => {
                                                    handleLanguageChange('en')
                                                    i18next.changeLanguage('en')
                                                    handleLinkClick()

                                                }}
                                            >
                                                <span className='lang_text'>English</span>
                                                <img src={img.EnLang} alt="images" />
                                            </div>
                                    }
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </Navbar>
            </Container>
        </div>
    )
}

export default NavBar
