import { ReactComponent as LangOutline } from 'assets/svg/Lang Icons.svg';

import { ReactComponent as Call } from 'assets/svg/Call outline.svg';
import { ReactComponent as Home } from 'assets/svg/Home Outline.svg';
import { ReactComponent as Email } from 'assets/svg/email.svg';
import { ReactComponent as Map } from 'assets/svg/map.svg';


import { ReactComponent as Service1 } from 'assets/svg/service1.svg';
import { ReactComponent as Service2 } from 'assets/svg/service2.svg';
import { ReactComponent as Service3 } from 'assets/svg/service3.svg';
import { ReactComponent as Service4 } from 'assets/svg/service4.svg';

import { ReactComponent as location } from 'assets/svg/location.svg';
import { ReactComponent as email } from 'assets/svg/email.svg';
import { ReactComponent as phone } from 'assets/svg/phone.svg';


import Facebook from 'assets/svg/Facebook';
import Instagram from 'assets/svg/Instagram';
import Linkedin from 'assets/svg/Linkedin';
import Twitter from 'assets/svg/Twitter';
import { ReactComponent as BgAbout2 } from 'assets/svg/bgAbout2.svg';
import { ReactComponent as BgContact } from 'assets/svg/bgContact.svg';
import { ReactComponent as Service5 } from 'assets/svg/service1 - Copy.svg';
import { ReactComponent as Service6 } from 'assets/svg/service2 - Copy.svg';
import { ReactComponent as Service7 } from 'assets/svg/service3 - Copy.svg';
import { ReactComponent as Service8 } from 'assets/svg/service4 - Copy.svg';
const Icons = {
    LangOutline,
    BgContact,
    Twitter,
    Facebook,
    Instagram,
    BgAbout2,
    Linkedin,
    Map,
    Email,
    Call,
    Home,
    Service1,
    Service2,
    Service3,
    location,
    email,
    phone,
    Service4,
    Service5,
    Service6,
    Service7,
    Service8,
}

export default Icons;
