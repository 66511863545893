import { useState } from "react";
import cookies from 'js-cookie';

 const useShare = () => { 
    const [isLoading, setIsLoading] = useState(true);
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': cookies.get('i18next'),
        }
    };
    return { 
        isLoading, setIsLoading,header
    }
}

export default useShare
