import Component from 'constants/Component'
import img from 'constants/Img'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const TwiningServices = () => {
    let { t } = useTranslation()
    return (
        <div className='app_services_twining'>
            <div className="header">
                <img src={img?.TwiningServicesBg} className='object-fit-cover w-100 ' alt=""  />
            </div>
            <Container>
                <main className='flex justify-content-center align-items-center flex-column mt-8 mb-8'>
                    <div className="img_logo mb-7">
                        <img src={img.logo} alt="" />
                    </div>
                    <Component.Btn title={t('nav_clients')} />

                    <section className='mt-5'>
                        <p>يقدم مصنع اغذية الخليج وجبات جافة لجميع المشاريع الكبيرة للمدارس او الجهات الحكومية و غيرها و ذلك بأفضل و أعلى معايير الجودة و السلامة الغذائية</p>
                    </section>
                </main>

            </Container>
        </div>
    )
}

export default TwiningServices
