import { ContantServices } from 'Page/CommingSoon/ContantServices';
import AOS from 'aos';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useContext, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import './style.scss';
import Component from 'constants/Component';
import { ApiContext } from 'context/FatchApi';
import Icons from 'constants/Icons';
import useShare from 'utils/useShare';
const ContactUs = () => {
    
    let { settings } = useContext(ApiContext);
    let { isLoading, setIsLoading } = useShare()

    let { isLang } = useContext(LocalizationContext)
    const { t } = useTranslation()

    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(isLang === "en" ? 'you name is required' : 'يرجى إدخال الاسم'),
        email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
        phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
        reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
        message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            reason: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            await ContantServices(isLang, values).then(({ data }) => {
                console.log(data?.message);

                resetForm();
            })
        }
    });

    var images = [img.img1, img.img2, img.img3]; // ضع مسار الصورتين هنا
    var images2 = [img.img4, img.img5, img.img6]; // ضع مسار الصورتين هنا
    let currentIndex = 0;
    let currentIndex2 = 0;

    useEffect(() => {
  
      
        AOS.init({
            duration: 1000,
            easing: 'ease-out-cubic',
        });
        window.scrollBy(0, -window.pageYOffset);
        const interval = setInterval(() => {
            document.getElementById('slideShow').src = images[currentIndex];
            currentIndex = (currentIndex + 1) % images.length;
            AOS.refresh();
        }, 3000);

        const interval2 = setInterval(() => {
            document.getElementById('slideShow2').src = images2[currentIndex2];
            currentIndex2 = (currentIndex2 + 1) % images2.length;
            AOS.refresh(); // لإعادة تحميل التأثيرات على العنصر الجديد
        }, 3500); // قد تحتاج إلى زيادة الوقت للسماح برؤية الانتقال

        return () => {
            clearInterval(interval)
            clearInterval(interval2);
        };
    }, []); 

    return (
        <div>
            <div className="app_contact_master">
                <div className='app_clients_home_container' >
                    <Row className='app_contact_master_page'>
                        <Col xl={6} lg={6} md={12} sm={12} className='app_form_contactus' dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <Form className="form" onSubmit={formik.handleSubmit} >
                                <Row className='mt-6'>
                                    <Col xl={6} lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='label-style'> {t('name')}    </Form.Label>
                                            <Form.Control
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.name && formik.errors.name}
                                                className='input-style' type="text" placeholder={t('placeholder_name')} />
                                            {formik.touched.name && formik.errors.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.name}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col xl={6} lg={6} md={6} sm={12}>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                            <Form.Label className='label-style'>{t('email')}</Form.Label>
                                            <Form.Control
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.email && formik.errors.email}
                                                className='input-style' type="email" placeholder={t('placeholder_email')} />
                                            {formik.touched.email && formik.errors.email && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.email}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col xl={6} lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                            <Form.Label className='label-style'>{t('phone')}</Form.Label>
                                            <Form.Control
                                                name="phone"
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.phone && formik.errors.phone}
                                                className='input-style' type="tel" placeholder={t('placeholder_phone')} />
                                            {formik.touched.phone && formik.errors.phone && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.phone}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col xl={6} lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                            <Form.Label className='label-style'> {t('reason')}</Form.Label>
                                            <Form.Control
                                                name="reason"
                                                value={formik.values.reason}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.reason && formik.errors.reason}
                                                className='input-style' type="text" placeholder={t('placeholder_reason')} />
                                            {formik.touched.reason && formik.errors.reason && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.reason}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                                            <Form.Label className='label-style'>{t('reason')}</Form.Label>
                                            <Form.Control
                                                name="message"
                                                value={formik.values.message}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.message && formik.errors.message}
                                                className='input-style' as="textarea" rows={5} placeholder={t('placeholder_message')} />
                                            {formik.touched.message && formik.errors.message && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="flex justify-content- align-items-center">
                                    <Button raised severity='warning' size='small' iconPos={isLang === "en" ? "left" : 'right'} icon="pi pi-arrow-left" type='submit' label={t('submit')} className='rounded-2 mt-4 p-2 px-4' />

                                </div>
                            </Form>
                            <div className="hr">
                                <hr />
                            </div>
                            <div className="calling">
                                <div className="call">
                                    <span className='flex gap-2  '>
                                        <div className="mt- ">
                                            <Icons.email />
                                        </div>
                                        <a href={`mailto:${settings?.email}`} target='_blank' className='text-dark' dir='ltr'>
                                            {settings?.email}
                                        </a>
                                    </span>
                                </div>
                                <div className="call">
                                    <span className='flex gap-2 align-items-center  '>
                                        <Icons.phone />
                                        <a href={`tel:${settings?.phone}`} target='_blank' className='text-dark' dir='ltr'>
                                            {settings?.phone?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} className='app_form_contactus_light d-flex flex-column justify-content-start align-items- '>
                            <div className="contant">
                                <span className='w-100 client_span'>{t('call_us')}</span>
                                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque reiciendis officiis minus nisi magnam sed!</p> */}
                            </div>
                            <div className="Container_img flex gap-8 mt-8">
                                <img data-aos="fade-up"
                                    data-aos-delay="1500"
                                    data-aos-duration="1000"
                                    data-aos-easing="ease-out-cubic"
                                    src="path_to_first_image.jpg"
                                    id="slideShow"
                                    width={200}
                                    className='object-fit-contain '
                                    alt="Slideshow Image" />


                                <img data-aos="fade-up"
                                    data-aos-delay="1500"
                                    data-aos-duration="1000"
                                    data-aos-easing="ease-out-cubic"
                                    src="path_to_first_image.jpg"
                                    id="slideShow2"
                                    width={200}
                                    alt="Slideshow Image" />
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
            <Component.Footer />

        </div>
    )
}

export default ContactUs