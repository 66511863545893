import Icons from 'constants/Icons';
import img from 'constants/Img';
import { ApiContext } from 'context/FatchApi';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Divider } from 'antd';

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  let { t } = useTranslation();
  let { isLang } = useContext(LocalizationContext);
  let { settings } = useContext(ApiContext);
  const location = useLocation();

  return (
    <div className="app__footer pt-40  overflow-hidden" dir={isLang === "en" ? 'ltr' : 'rtl'}>
      <Container>
        <Row>
          <Col
            xl={4} lg={4} md={6} sm={12}
            className='mt-4 order-md-4 order-lg-0 order-sm-4 footer_lg px-5'
            data-aos="fade-left"
          >
            <div className="flex flex-column gap-3 mt-35">
              <a>
                <img src={img.logo} alt="images" width={179} />
              </a>
              {/* <p>{settings?.description}</p> */}
            </div>
            <div className='footer_Links mt-5'>
              <div className="footer_ui">
                <ul>
                  <li className='flex gap-2  '>
                    <div className="">
                      <Icons.location />
                    </div>
                    {settings?.address}
                  </li>
                  <li className='flex gap-2  '>
                    <div className="mt- ">
                      <Icons.email />
                    </div>
                    <a href={`mailto:${settings?.email}`} target='_blank' className='text-dark' dir='ltr'>
                      {settings?.email}
                    </a>
                  </li>
                  <li className='flex gap-2  '>
                    <div className="  icon_footer ">
                      <Icons.phone />
                    </div>
                    <a href={`tel:${settings?.phone}`} target='_blank' className='text-dark' dir='ltr'>
                      {settings?.phone?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={1} lg={1} md={12} sm={12}  ></Col>

          <Col xl={2} lg={2} md={12} sm={12} className='flex flex-row gap-sm-8 mt-7 px-5'     data-aos="fade-up">
       
              <div className='footer_Links'>
                <h1>{isLang === "en" ? 'Pages' : 'الصفــحــات'}</h1>
                <div className="footer_ui">
                  <ul>
                    <li>
                      <Nav.Link
                        as={Link}
                        to="/"
                        className={`nav-link ${location.pathname === '/' ? 'active active_main_color' : ''}`}
                      >
                        {t('nav_home')}
                      </Nav.Link>
                    </li>
                    <li>
                      <Link
                        to={'/about'}
                        className={`nav-link ${location.pathname === '/about' ? 'active active_main_color' : ''}`}
                      >
                        {t('nav_about')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/products'}
                        className={`nav-link ${location.pathname === '/products' ? 'active active_main_color' : ''}`}
                      >
                        {t('nav_service')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={'/contact-us'}
                        className={`nav-link ${location.pathname === '/contact-us' ? 'active active_main_color' : ''}`}
                      >
                        {t('nav_connect')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> 
          </Col>

          <Col
            xl={5} lg={5} md={6} sm={12}
            className='mt-  order-md-1 order-lg-1 order-sm-1 footer_lg px-5'
            data-aos="fade-right"
          >
            <Row >
              <Col xs={6} xl={6} lg={6} md={6} sm={6}>
                <img src={img.kako} width={100} height={100} className='object-fit-contain' alt="" srcset="" />
                <div className="top-bar mt-5">
                  <div className="top-bar-content" dir={isLang == "ar" ? 'ltr' : 'rtl'} >
                    <div className="social-media-icons" >
                      <a className="title_social" href={settings?.twitter1} target="_blank" rel="noopener noreferrer">
                        <span className="twitter-icon">
                          <div className="  d-flex justify-content-center align-items-center">
                            <Icons.Twitter color={'#ffff'} />
                          </div>
                        </span>
                        <span>{t('Twitter')}</span>
                      </a>
                      <a className="title_social" href={settings?.tiktok1} target="_blank" rel="noopener noreferrer">
                        <span className="facebook-icon">
                          <a className="  d-flex justify-content-center align-items-center" href={settings?.tiktok1} target="_blank" rel="noopener noreferrer">
                            <Icons.Facebook color={'#ffff'} />
                          </a>
                        </span>
                        <span>{t('facebook')}</span>
                      </a>
                      <a className="title_social" href={settings?.instagram1} target="_blank" rel="noopener noreferrer">

                        <span className="instagram-icon">
                          <a className="  d-flex justify-content-center align-items-center" href={settings?.instagram1} target="_blank" rel="noopener noreferrer">
                            <Icons.Instagram color={'#ffff'} />
                          </a>
                        </span>
                        <span>{t('instagram')}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col  xs={6}xl={6} lg={6} md={6} sm={6}>
                <img src={img.pronto} width={100} height={100} className='object-fit-contain' alt="" srcset="" />
                <div className="top-bar mt-5">
                  <div className="top-bar-content" dir={isLang == "ar" ? 'ltr' : 'rtl'} >
                    <div className="social-media-icons" >
                      <a className="title_social" href={settings?.twitter2} target="_blank" rel="noopener noreferrer">
                        <span className="twitter-icon">
                          <a className="  d-flex justify-content-center align-items-center" href={settings?.twitter2} target="_blank" rel="noopener noreferrer">
                            <Icons.Twitter color={'#ffff'} />
                          </a>
                        </span>
                        <span>{t('Twitter')}</span>
                      </a>
                      <a className="title_social" href={settings?.facebook2} target="_blank" rel="noopener noreferrer">
                        <span className="facebook-icon">
                          <a className="  d-flex justify-content-center align-items-center" href={settings?.facebook2} target="_blank" rel="noopener noreferrer">
                            <Icons.Facebook color={'#ffff'} />
                          </a>
                        </span>
                        <span>{t('facebook')}</span>
                      </a>
                      <a className="title_social" href={settings?.instagram2} target="_blank" rel="noopener noreferrer">
                        <span className="instagram-icon">
                          <a className="  d-flex justify-content-center align-items-center" href={settings?.instagram2} target="_blank" rel="noopener noreferrer">
                            <Icons.Instagram color={'#ffff'} />
                          </a>
                        </span>
                        <span>{t('instagram')}</span>
                      </a>

                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row >
        <Divider plain />
        <div className="footer__buttom pb-5  flex justify-content-center align-items-center">
          <span className='text-center'>
            Copyright © 2024, <a href="https://rightclick.sa/" target='_blank' className='rightClick'>RightClick</a>, All Rights Reserved
          </span>
        </div>
      </Container >
    </div >
  );
}

export default Footer;
