// import Auth from 'Layout/Auth';
import CommingSoon from 'Page/CommingSoon/CommingSoon';
import NotFound from 'components/notfound/NotFound';
import Bakery from 'Layout/Bakery';
import Home from 'Page/Home/Home';
import About from 'Page/About/About';
import ContactUs from 'Page/ContactUs/ContactUs';
import Btn from 'components/btn/Btn';
import TwiningServices from 'Page/TwiningServices/TwiningServices';
import Products from 'Page/products/Products';
import Details from 'Page/products/Details';
import Category from 'Page/products/Category';
import BrandAboutUs from 'Page/Home/Brand/BrandAboutUs';
import BrandComponents from 'Page/Home/Brand/BrandComponents';
import Loader from 'components/Loader/Loader';
import Footer from 'components/Footer/Footer';
import Gullery from 'Page/gallery/Gullery';

const Component = {
    // Auth,
    CommingSoon,
    Bakery,
    NotFound,
    Home,
    About,
    Btn,
    TwiningServices,
    ContactUs,
    Details,
    Category,
    BrandAboutUs,
    BrandComponents,
    Products,
    Footer,
    Gullery,
    Loader

}

export default Component




