import React, { useEffect, useState } from 'react'
import './details.scss'
import { Col, Image, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetData } from 'utils/fetchData'
import { useParams } from 'react-router-dom'
import useShare from 'utils/useShare'
import Aos from 'aos'
import Component from 'constants/Component'

const Details = () => {
  let { t, i18n } = useTranslation()
  let { id } = useParams()
  const url = `${process.env.REACT_APP_API_URL}/products/${id}/details`;

  let { isLoading, setIsLoading, header } = useShare();
  const [data, setData] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    GetData(url, header).then((data) => {
      if (data?.status === 200) {
        setData(data?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }
    });
  };

  useEffect(() => {
    fetchData();
    window.scrollBy(0, -window.pageYOffset);
    Aos.init({ duration: 1500 });
    Aos.refresh();
  }, [i18n?.language]);

  if (isLoading) {
    return <Component.Loader />;
  }

  return (
    <>
      <div className="app_prodacts_details" data-aos="fade-up">
        <div className='app_prodacts_details_container'>
          <header className='shadow'>
            <h2>{t('details_prodacts')}</h2>
          </header>

          <section className='mt-6'>
            <Row>
              <Col 
                xs={24} 
                lg={12} 
                xl={12} 
                md={12} 
                sm={24} 
                className='images_product_details flex justify-content-center align-items-center'
                data-aos="fade-right"
              >
                <Image src={data?.image} alt="images" />
              </Col>

              <Col 
                xs={24} 
                lg={12} 
                xl={12} 
                md={12} 
                sm={24} 
                data-aos="fade-left"
              >
                <div className="details_content">
                  <div className="content">
                    <strong>{t('product_name')}</strong>
                    <p>{data?.title}</p>
                  </div>
             
                  <div className="content">
                    <strong>{t('product_dec')}</strong>
                    <p dangerouslySetInnerHTML={{ __html: data?.description }} />
                  </div>
                  <div className="content mt-5">
                    <strong>{t('product_optional')}</strong>
                    <div className="choose">
                      <div className="content flex m-0 p-0 flex-row gap-4 align-items-center">
                        <p>{t('product_brand')}</p>
                        <span>{data?.brand_title}</span>
                      </div>
                    </div>
                    <div className="choose">
                      <div className="content flex m-0 p-0 flex-row gap-4 align-items-center">
                        <p>{t('Category')}</p>
                        <span>{data?.category_title}</span>
                      </div>
                    </div>
              
                    <div className="choose m-0 p-0">
                      <div className="content m-0 p-0 flex flex-row gap-4 align-items-center">
                        <p>{t('product_size')}</p>
                        <span>  {data?.size} {t('product_size_type')} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
      <Component.Footer />
    </>
  )
}

export default Details;
