import { Button } from 'primereact/button';
import React from 'react';
import { Link } from 'react-router-dom';

const Btn = ({ title, link }) => (
    link ? (
        <Link to={link} className='btn_border_radius'>
            <Button className="mt-5" label={title} severity='warning'  />
        </Link>
    ) : (
        <div className="btn1 btn_border_radius">
            <Button label={title} severity='warning' size='small' />
        </div>
    )
);

export default Btn;
