import 'aos/dist/aos.css';
import img from 'constants/Img';
import { Col, Container, Row } from 'react-bootstrap';
import './brand.scss';
import { useTranslation } from 'react-i18next';
import Component from 'constants/Component';

const BrandAboutUs = ({data}) => {
    let { t } = useTranslation()

    return (
        <div className=" mt-6   p-0 app__component flex justify-content-center">
            <div className="app__component_container m-0 p-0">
                <Container>
                <Row>
                    <Col xl={4} lg={4} md={12} sm={12} data-aos-delay={500} data-aos="fade-right">
                        <div className="app__summary_title   mt-7 flex justify-content-center flex-column align-items-start w-100">
                            <div className="header">
                                <h3>{data?.section_title}</h3> 
                            </div>
                        </div>
                        <div className="content_about mt-4"> 
                            <p>{data?.section_content}</p>
                         </div>
                         {/* <Component.Btn title={t('order_now')} link={'/contact-us'} /> */}
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12} className='  distribution d-flex justify-content-end align-items-center' data-aos-delay={1000}data-aos="zoom-in">
                        <img src={img.Distribution} alt="distribution"   />
                    </Col>
                </Row>

                </Container>
            </div>
        </div>
    )
}

export default BrandAboutUs
