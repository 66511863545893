import Icons from 'constants/Icons';
import { LocalizationContext } from 'context/LangChange';
import React, { useContext } from 'react';
import { Col, Row, Form, Input, Button } from 'antd';
import './connect.scss';
import { ContantServices } from './ContantServices';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const ConnectHome = () => {
  let { isLang } = useContext(LocalizationContext);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(isLang === "en" ? 'Your name is required' : 'يرجى إدخال الاسم'),
    email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
    phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
    reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
    message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      reason: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      await ContantServices(isLang, values).then(({ data }) => {
        console.log(data?.message);
        resetForm();
      });
    }
  });

  return (
    <div className='py-8 app_clients_home'>
      <div className='app_clients_home_container app_clients_home_container_home' data-aos-delay={200} data-aos="fade-up">
        <Row>
          <Col xl={12} lg={12} md={24} sm={24} dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <span className='w-100 client_span'>{t('contact_title')}</span>
            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Row gutter={[16, 10]} className='mt-4'>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    label={t('name')}
                    validateStatus={formik.touched.name && formik.errors.name ? 'error' : ''}
                    help={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                  >
                    <Input
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t('placeholder_name')}
                    />
                  </Form.Item>
                </Col>

                <Col xl={12} lg={12} md={12} sm={24} xs={24} >
                  <Form.Item
                    label={t('email')}
                    validateStatus={formik.touched.email && formik.errors.email ? 'error' : ''}
                    help={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                  >
                    <Input
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t('placeholder_email')}
                    />
                  </Form.Item>
                </Col>

                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    label={t('phone')}
                    validateStatus={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                    help={formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}
                  >
                    <Input
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t('placeholder_phone')}
                    />
                  </Form.Item>
                </Col>

                <Col xl={12} lg={12} md={12} sm={24}  xs={24}>
                  <Form.Item
                    label={t('reason')}
                    validateStatus={formik.touched.reason && formik.errors.reason ? 'error' : ''}
                    help={formik.touched.reason && formik.errors.reason ? formik.errors.reason : ''}
                  >
                    <Input
                      name="reason"
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t('placeholder_reason')}
                    />
                  </Form.Item>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('message')} 
                    validateStatus={formik.touched.message && formik.errors.message ? 'error' : ''}
                    help={formik.touched.message && formik.errors.message ? formik.errors.message : ''}
                  >
                    <TextArea
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      rows={5}
                      placeholder={t('placeholder_message')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-content-center align-items-center btn_submit">
                <Button
                  size='large'
                  type='primary'
                  htmlType='submit' 
                  className='rounded-0 mt-4'
                >
                  {t('submit')}
                </Button>
              </div>
            </Form>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} className='d-flex justify-content-start align-items-center'>
            <Icons.Map className='w-100' />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ConnectHome;
