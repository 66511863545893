import Aos from 'aos';
import 'aos/dist/aos.css';
import Component from 'constants/Component';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GetData } from 'utils/fetchData';
import useShare from 'utils/useShare';
import './style.scss';

const About = () => { 
  const { isLoading, setIsLoading, header } = useShare();
  const { isLang } = useContext(LocalizationContext);
  const [data, setData] = useState(null);
  const [dataAbout, setDataAbout] = useState(null);
 
  const url = `${process.env.REACT_APP_API_URL}/about-us`;
 

  const fetchData = async () => {
    try {
      const response = await GetData(url, header);
      if (response?.status === 200) {
        setData(response?.data);
        setDataAbout(response?.data?.data);
        setTimeout(() => setIsLoading(false), 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollBy(0, -window.pageYOffset);
    Aos.init({ duration: 1500 });
  }, [isLang]);

  if (isLoading) {
    return <Component.Loader />;
  }

  return (
    <div className='app_about_master'>
      <div className="header" data-aos="fade-up">
        <img src={dataAbout?.[2]?.image} className='object-fit-cover w-100' alt="" />
      </div>
      <div className="Container_content" data-aos="fade-up">
        <Component.Btn title={dataAbout?.[2]?.title} />
        <div className='blog_details_data' dangerouslySetInnerHTML={{ __html: dataAbout?.[2]?.description }} />
      </div>
      <Row className="flex flex-row about_us_contents mt-6">
        {dataAbout?.slice(0, 2).map((item, index) => (
          <Col key={index} xl={6} lg={6} md={12} sm={12} data-aos="fade-up">
            <img src={item?.image} className='object-fit-cover w-100' alt="" />
            <div className="content">
              <Component.Btn title={item?.title} />
              <div className='blog_details_data' dangerouslySetInnerHTML={{ __html: item?.description }} />
            </div>
          </Col>
        ))}
      </Row>
      <div className="overflow-hidden mb-8" data-aos="fade-up">
        <Row dir='rtl'>
          <Col xl={6} lg={6} md={6} sm={12} className='Container_content2' dir={isLang === "en" ? 'ltr' : 'rtl'}>
            {dataAbout?.slice(3, 5).map((item, index) => (
              <div key={index} className="Bakery_industry mt-6" data-aos="fade-up">
                <Component.Btn title={item?.title} />
                <div className='blog_details_data_Bakery_industry' dangerouslySetInnerHTML={{ __html: item?.description }} />
              </div>
            ))}
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} className='flex conlection_img_master justify-content-center' data-aos="fade-up">
            <div className="conlection_img">
              <img src={img.BgAbout1} width={350} alt="image about" className='img_lg' />
              <img src={img.BgAbout2} width={250} className='img_sm' alt="image about" />
            </div>
          </Col>
        </Row>
      </div>

      <Component.Footer />
    </div>
  );
};

export default About;
