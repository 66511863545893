import { Spin } from 'antd'
import React from 'react'

const Loader = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#fff', position: 'relative', zIndex: '9999999999' }}>
            <Spin size="large" />
        </div>
    )
}

export default Loader
