import React, { createContext, useEffect, useState } from 'react';
import cookies from 'js-cookie';

export const LocalizationContext = createContext();

const LangChange = ({ children }) => {

  const [isLang, setIsLang] = useState(cookies.get('i18next') || 'en');

  useEffect(() => {
    cookies.set('i18next', isLang);
  }, [isLang]);

  return (
    <LocalizationContext.Provider value={{ isLang, setIsLang }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export default LangChange;