''
import { WhatsAppOutlined } from '@ant-design/icons';
import { ConfigProvider, FloatButton } from 'antd';
import Component from 'constants/Component';
import FatchApi, { ApiContext } from 'context/FatchApi';
import LangChange from 'context/LangChange';
import { Toaster } from 'react-hot-toast';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './style/App.scss';
import { useContext } from 'react';

function App() {
  let { settings } = useContext(ApiContext);


  
  const root = createBrowserRouter([
    {
      path: '/', element: <Component.Bakery />, children: [
        { index: true, element: <Component.Home /> },
        { path: 'about', element: <Component.About /> },
        { path: 'contact-us', element: <Component.ContactUs /> },
        { path: 'services', element: <Component.TwiningServices /> },
        { path: 'gallery', element: <Component.Gullery /> },
        {
          path: '/products', children: [
            { index: true, element: <Component.Products /> },
            { path: 'brand/:id', element: <Component.Category /> },
            { path: 'category/:id/:idBrand', element: <Component.Category /> },
            { path: 'details/:id', element: <Component.Details /> }
          ]
        },

        { path: '*', element: <Component.NotFound /> },
      ]
    },
  ])
  return (
    <>
      {
        settings?.whats &&
        <FloatButton
          href={`https://wa.me/${settings?.whats}`}
          icon={<WhatsAppOutlined />}
          target='_blank'
          style={{ background: '#f5af69' }}
          className="pulse-animation" // إضافة هذه السطر لتطبيق الأنيميشن
        />
      }
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: ' Arial, Helvetica, sans-serif',
            textTransform: 'capitalize',
            zIndex: '9999',
            // background: '#000',
            // color: '#fff',
            borderRadius: '10px',
            boxShadow: '10px 10px 10px rgba(188, 188, 188, 0.16)',
            background: '#fff',
            color: '#000',
          },
        }}
        containerStyle={{
          bottom: 50
        }}
      />
      <ConfigProvider
        theme={{
          components: {
            Carousel: {
              colorPrimary: "#FF8C40",
              algorithm: true,
            },
            FloatButton: {
              colorPrimary: '#FF8C40',
              algorithm: true,

            },
            Spin: {
              colorPrimary: '#FF8C40',
              algorithm: true,
            },
            Button: {
              colorPrimary: '#FF8C40',
              algorithm: true,
              borderRadius: '3px'
            },
            Input: {
              colorPrimary: '#FF8C40',
              algorithm: true,

            },
            Drawer: {
              colorPrimary: '#FF8C40',
            },
            Tabs: {
              colorPrimary: '#FF8C40',
              algorithm: true,
            },
            Checkbox: {
              colorPrimary: '#27BDB0',
              algorithm: true,

            },
          },
        }}
      >
        <LangChange>
          <FatchApi>
            <RouterProvider router={root} />
          </FatchApi>
        </LangChange>

      </ConfigProvider>
    </>
  );
}

export default App;
