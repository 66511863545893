import EnLang from 'assets/Images/En.png';
import BgAbout1 from 'assets/Images/LEFTIMG.png';
import About1 from 'assets/Images/about1.png';
import About2 from 'assets/Images/about2.png';
import About3 from 'assets/Images/about3.png';
import ArLang from 'assets/Images/ar.png';
import TwiningServicesBg from 'assets/Images/bg-TwiningServices.png';
import chef from 'assets/Images/chef.png';
import DefaultImage from "assets/Images/default-image.jpg";
import img4 from 'assets/Images/img (1).png';
import img2 from 'assets/Images/img (2).png';
import img1 from 'assets/Images/img (3).png';
import img3 from 'assets/Images/img (4).png';
import img5 from 'assets/Images/img (5).png';
import img6 from 'assets/Images/img (6).png';
import BgAbout2 from 'assets/Images/left img.png';
import { default as loginBg, default as logo } from "assets/Images/logo.png";
import logo_light from "assets/Images/logo_light.png";

import kako from 'assets/Images/kako.png';
import pronto from 'assets/Images/pronto.png';
import Distribution from 'assets/Images/Distribution.png';

// kako
// pronto


const img = {
    Distribution,

    kako,
    pronto,
    loginBg,
    TwiningServicesBg,
    chef,
    BgAbout1,
    BgAbout2, 
    logo,
    logo_light,
    DefaultImage,
    EnLang,
    ArLang,
    About1,
    About2,
    About3,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6, 
}

export default img;
