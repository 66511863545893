import { CaretLeftFilled, DownOutlined } from '@ant-design/icons'
import { Button, Card, Col, Dropdown, Menu, Rate, Row } from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'

import Aos from 'aos'
import { LocalizationContext } from 'context/LangChange'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetData } from 'utils/fetchData'
import useShare from 'utils/useShare'
import './category.scss'
import Component from 'constants/Component'

const Category = () => {
  let { id, idBrand } = useParams()
  const location = useLocation(); // Get current location

  let { t } = useTranslation();
  let { isLoading, setIsLoading, header } = useShare();
  let { isLang } = useContext(LocalizationContext);
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);

  const fetchDataBrand = async (url, id) => {
    setIsLoading(true);
    GetData(`${process.env.REACT_APP_API_URL}/${url}/${id}`, header).then((data) => {
      if (data?.status === 200) {
        setData(data?.data);
        console.log(data?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }
    });
  };

  const fetchCategoriesAjex = async (id) => {
    GetData(`${process.env.REACT_APP_API_URL}/categories/${id}`, header).then((data) => {
      if (data?.status === 200) {
        setCategories(data?.data);
      }
    });
  };

  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
    if (location.pathname === `/products/brand/${id}`) {
      fetchDataBrand('brand-products', id);
    } else {
      fetchDataBrand('products', id);
    }
    if (location.pathname === `/products/category/${id}/${idBrand}`) {
      fetchCategoriesAjex(idBrand);
    }
  }, [isLang]);

  const menu = (
    <Menu>
      {
        categories?.map((item) => (
          <Menu.Item onClick={() => fetchDataBrand('products', item.id)} key={item.id}>
            {item?.name}
          </Menu.Item>
        ))
      }
    </Menu>
  );

  useEffect(() => {
    Aos.init({
      duration: 1500
    });
    Aos.refresh();
  }, []);

  if (isLoading) {
    return <Component.Loader />;
  }

  return (
    <>
      <div className="app_category">
        <div className='app_category_container'>
          <header>
            <div className="app_category_header shadow-sm mt-1" data-aos="fade-up">
              {
                location.pathname === `/products/brand/${id}` ?
                  <Row>
                    <Col xs={24} sm={12} md={10} lg={12} className="category-details-col">
                      <div className="category-details">
                        <img src={data?.brand_logo} alt="logo brand pronto" />
                        <div className="content">
                          <strong>{data?.brand_title}</strong>
                          <p dangerouslySetInnerHTML={{ __html: data?.brand_description }} />
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={12} className="flex justify-content-center align-items-center">
                      <div className="app_category_main_image">
                        <img src={data?.brand_image} alt="" />
                      </div>
                    </Col>
                  </Row> :
                  <Row>
                    <Col xs={24} sm={12} md={10} lg={12} className="category-details-col">
                      <div className="category-details">
                        <img src={data?.brand_logo} alt="logo brand pronto" />
                        <div className="content">
                          <strong>{data?.category_title}</strong>
                          <p dangerouslySetInnerHTML={{ __html: data?.category_description }} />
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={12} className="flex justify-content-center align-items-center">
                      <div className="app_category_main_image">
                        <img src={data?.category_image} alt="" />
                      </div>
                    </Col>
                  </Row>
              }
            </div>
          </header>
          <section>
            <div className="choose_category">
              <div className="navigation-container">
                <Link to={'/products'}>
                  <Button size='large' type="primary" icon={<CaretLeftFilled />} iconPosition='end'>{t('btn_prodacts')}</Button>
                </Link>
                {location.pathname === `/products/category/${id}/${idBrand}` && (
                  <div className="btn_main">
                    <span className='title'>{t('Category')}</span>
                    <Dropdown overlay={menu}>
                      <Button size='large'>{t('dropdown_prodacts')}<DownOutlined /></Button>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </section>
          <Row gutter={[30, 30]} justify="center">
            {data?.products.map(product => (
              <Col xs={24} sm={12} md={8} lg={8} key={product.id}>
                <Card
                  data-aos="fade-up"
                  data-aos-duration="1000" data-aos-easing="linear"
                  className='app_category_card'
                  hoverable={false}
                  cover={<img alt={product.name} src={product.image} />}
                >
                  <div className="content">
                    <div className="title flex justify-content-between flex-column gap-2">
                      <strong>{product.title}</strong>
                      <Rate disabled defaultValue={product.rate} />
                    </div>
                    <div className="des mt-2">
                      {/* <p dangerouslySetInnerHTML={{ __html: product?.description }} /> */}
                    </div>
                  </div>
                  <div className="des mt-4">
                    <Link to={`/products/details/${product.id}`}>
                      <Button size='middle' type="primary" className='w-100'>{t('btn_details')}</Button>
                    </Link>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Component.Footer />
    </>
  )
}

export default Category;
