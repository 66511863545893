import 'aos/dist/aos.css';
import img from 'constants/Img';
import { Col, Row } from 'react-bootstrap';
import './brand.scss';
import { useTranslation } from 'react-i18next';

const BrandComponents = ({ data }) => {
    let { t } = useTranslation()
    return (
        <div className="app__component">
            <div className="app__component_container">
                <Row className='app_chif'>
                    <Col xl={7} lg={7} md={12} sm={12} className='chef d-flex justify-content-center align-items-center' data-aos-delay={500}data-aos="zoom-in">
                        <div className="chef_bg"></div>
                        <div className="chef_bg2"></div>
                        <img src={img.chef} alt="" />
                    </Col>
                    <Col xl={5} lg={5} md={12} sm={12} data-aos-delay={500} data-aos="fade-right" className='ap_flex_sm'>
                        <div className="app__summary_title flex justify-content-center flex-column align-items-start w-100">
                            <div className="header">
                                <h3>{t('title_Ingredients')}</h3>
                            </div>
                        </div>
                        <div className="content_about">  
                            <span>{data?.section_content}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default BrandComponents
