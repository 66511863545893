import { Carousel } from 'antd';
import { LocalizationContext } from 'context/LangChange';
import { useContext } from 'react';
import './slider.scss'; // تأكد من تحديث هذا الملف بأنماط CSS الجديدة
import img from 'constants/Img';
import { useEffect } from 'react';

const SliderHome = ({ data }) => {
    const { isLang } = useContext(LocalizationContext)
    const onChange = (currentSlide) => {
    };
    useEffect(() => {
        console.log(data);
    }, []);

    return (
        <Carousel autoplay infinite={true} speed={2000} swipeToSlide={true} afterChange={onChange}>
            {data?.map((item, index) => (
                <div key={index} className='slide-container flex justify-content-center align-items-center  '>
                    <img src={item.image} alt="" className="w-100 object-fit-cover" />
                    <div className="overlay"></div>
                </div>
            ))}
        </Carousel>
    )
}

export default SliderHome;
