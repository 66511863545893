import Footer from 'components/Footer/Footer';
import NavBar from 'components/Navbar/NavBar';
import { Outlet } from 'react-router-dom';

const Bakery = () => {
 

  return (
      <div className='relative ' style={{ overflow: 'hidden' }}>
        <NavBar />
        <Outlet />
      </div> 
  );
};

export default Bakery;
