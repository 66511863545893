import 'aos/dist/aos.css';
import Component from 'constants/Component';
import img from 'constants/Img';
import { Col, Row } from 'react-bootstrap';
import './about.scss';
import { useTranslation } from 'react-i18next';

const About = ({data}) => {
    let {t}=useTranslation()
    
    return (
        <>
            <div className="app__about_home">
                <div className='app__about_container'>
                    <Row>
                        <Col xl={5} lg={5} md={12} sm={12}>
                            <div className="app__summary_title flex justify-content-center flex-column align-items-start w-100" data-aos="fade-right">
                                <div className="header">
                                    <h3 className="animate__animated animate__fadeIn">{t('title_about_us')}</h3>
                                </div>
                            </div>
                            <div className="content_about" data-aos="fade-up">
                                <p className="animate__animated animate__fadeIn">{data?.section_title}</p>
                                <span className="animate__animated animate__fadeIn">{data?.section_content}</span>
                            </div>

                            <Component.Btn title={t('see_more')} link={'/about'} />
                        </Col>
                        <Col xl={7} lg={7} md={12} sm={12} className='  mt-4 image_right_sm'>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} data-aos="fade-right" className="animate__animated">
                                    <img src={img.About3} className='  image_right1'  alt="About Image 3" />
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6} className='flex flex-column gap-4'>
                                    <img src={img.About2} data-aos="fade-up" className="image_right2 animate__animated" alt="About Image 2" />
                                    <img src={img.About1} data-aos="fade-up" className="image_right3 animate__animated" alt="About Image 1" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default About;
