import 'aos/dist/aos.css';
import Component from 'constants/Component';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './brand.scss';

const Brand = ({ data }) => {
    let { t } = useTranslation()

    return (
        <>
            <div className="app__brnad">
                <div className="app__brnad_container">
                    <Row>
                        <Col xl={5} lg={5} md={12} sm={12} data-aos="fade-right">
                            <div className="app__summary_title flex justify-content-center flex-column align-items-start w-100">
                                <div className="header">
                                    <h3>{t('title_brands')}</h3>
                                </div>
                            </div>
                            <div className="content_about">
                                <p>{data?.section_title}</p>
                                <span>{data?.section_content}</span>
                            </div>
                            <Component.Btn title={t('see_more')} link={'/products'} />
                        </Col>
                        <Col xl={7} lg={7} md={12} sm={12} className='d-flex h-100 justify-content-center align-items-center' data-aos="fade-left">
                            <Row className="brands_img h-100">
                                {
                                    data?.data?.map((item, index) => (
                                        <Col key={index} xl={6} lg={6} md={12} sm={12} xs={6} data-aos="zoom-in">
                                            <div className="brand">
                                                <img src={item?.logo} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                }
                          
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    );
}

export default Brand;
