import { Card, Col, Row, Skeleton } from 'antd';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GetData } from 'utils/fetchData';
import useShare from 'utils/useShare';
import './prodact.scss';
import Component from 'constants/Component';

const Products = () => {
    const url = `${process.env.REACT_APP_API_URL}/products`;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [galleryBrand1, setGalleryBrand1] = useState([]);
    const [galleryBrand2, setGalleryBrand2] = useState([]);

    const { t } = useTranslation();
    const { isLoading, setIsLoading, header } = useShare();
    const { isLang } = useContext(LocalizationContext);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        const response = await GetData(url, header);
        if (response?.status === 200) {
            const fetchedData = response?.data;
            setData(fetchedData[0]);
            setGalleryBrand1(fetchedData[0].gallery.map(item => item?.image));
            setData2(fetchedData[1]);
            setGalleryBrand2(fetchedData[1].gallery.map(item => item?.image));
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        }
    };

    useEffect(() => {
        fetchData();
        Aos.init({ duration: 1500 });
    }, [isLang]);

    const setupInterval = (data, setCurrentIndex, currentIndex) => {
        if (data && data.gallery && data.gallery.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % data?.gallery?.length);
            }, 3000);
            return () => clearInterval(interval);
        }
    };

    useEffect(() => setupInterval(data, setCurrentIndex, currentIndex), [data]);
    useEffect(() => setupInterval(data2, setCurrentIndex2, currentIndex2), [data2]);

    const fallbackImage = <Skeleton.Image active />;

    const ProductSection = ({ productData, gallery, currentIndex }) => (
        <div className='app_products_page'>
            <div className="app_products" >
                <header>
                    <Container>
                        <Row className="product-row">
                            <Col  xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '40%' }} lg={{ flex: '50%' }} xl={{ flex: '30%' }} className="product-details-col">
                                <div className="product-details"  >
                                    {productData?.image ? <img src={productData?.image} alt="logo brand pronto" /> : fallbackImage}
                                    <div className="content mt-4">
                                        <div className='mt-3' dangerouslySetInnerHTML={{ __html: productData?.description }} />
                                        <Link to={`/products/brand/${productData?.id}`}>
                                            <button>{t('btn2_more')}</button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '40%' }} lg={{ flex: '50%' }} xl={{ flex: '70%' }} className="product-image-col" >
                                <div className="brand_header flex flex-row">
                                    <div className="img_brand" data-aos="zoom-in"
                                        data-aos-duration="2000" data-aos-easing="linear">
                                        {gallery?.length <= 0 ?
                                            fallbackImage :
                                            <img src={gallery[currentIndex]} alt={`Brand Image ${currentIndex + 1}`} className='w-100' />
                                        }
                                    </div>
                                    <div className="content_brand" data-aos="fade-right"
                                        data-aos-duration="2000" data-aos-easing="linear">
                                        <div className="title">
                                            <strong>{productData?.gallery[currentIndex]?.title}</strong>
                                            <div className='mt-3' dangerouslySetInnerHTML={{ __html: productData?.gallery[currentIndex]?.description }} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </header>
            </div>
            <Container className='products_items'>
                <section>
                    <Row gutter={[16, 16]}   style={{ maxWidth: '100%', margin: '0 auto' }}>
                        {productData?.categories?.map((item, index) => (
                            <Col key={index} xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '40%' }} lg={{ flex: '20%' }} xl={{ flex: '20%' }}  >
                                <Link to={`/products/category/${item.id}/${productData?.id}`}>
                                    <Card
                                        hoverable
                                        cover={item?.image ? <img src={item?.image} className='brands_prodacts_img' alt={`Product ${index + 1}`} /> : fallbackImage}
                                        bordered={false}
                                        className="custom-card"
                                    >
                                        <div className="custom-card-content">
                                            <strong>{item?.name}</strong>
                                            <div className="mt-2">
                                                <p>{item?.products}</p>
                                                <span>{t('see_more')}</span>
                                            </div>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </section>
            </Container>
        </div>
    );

    if (isLoading) {
        return <Component.Loader />;
    }

    return (
        <>
            {data && <ProductSection productData={data} gallery={galleryBrand1} currentIndex={currentIndex} />}
            {data2 && <ProductSection productData={data2} gallery={galleryBrand2} currentIndex={currentIndex2} />}
            <Component.Footer />
        </>
    );
};

export default Products;
