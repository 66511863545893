
import { HomeOutlined, InfoCircleOutlined,ProductOutlined,
    CustomerServiceOutlined,
    ContactsOutlined } from '@ant-design/icons';

const routes = [
    {
        path: "/",
        nameEn: "Home",
        nameAr: "الرئيسية",
        icon: <HomeOutlined />,
    },
    {
        path: "/about",
        nameEn: "about Us",
        nameAr: "نبــذه عنــا",
        icon: <InfoCircleOutlined />,
    },

    {
        path: "/products",
        nameEn: "  Our products",
        nameAr: "منتجـاتنــا",
        icon: <ProductOutlined />,
    },
    {
        path: "/gallery",
        nameEn: "Gallery",
        nameAr: "مــعرض الصـــور",
        icon: <CustomerServiceOutlined />,
    },


    {
        path: "/contact-us",
        nameEn: "Contact Us ",
        nameAr: "تـواصل معنــا",
        icon: <ContactsOutlined />,

    },



];

export default routes