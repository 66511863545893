
import Aos from 'aos';
import 'aos/dist/aos.css';
import Component from 'constants/Component';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import { GetData } from 'utils/fetchData';
import useShare from 'utils/useShare';
import About from './About/About';
import Brand from './Brand/Brand';
import ConnectHome from './Connect/ConnectHome';
import SliderHome from './Slider/SliderHome';

const Home = () => {
    const url = `${process.env.REACT_APP_API_URL}/home`;
    let { isLoading, setIsLoading, header } = useShare()
    let { isLang } = useContext(LocalizationContext);

    const [data, setData] = useState(null);

    const fetchData = async () => {
        GetData(url, header).then((data) => {
            if (data?.status === 200) {
                setData(data);
                console.log(data?.sliders);
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            }
        })

    };

    useEffect(() => {
        fetchData(); 
        window.scrollBy(0, -window.pageYOffset);
        Aos.init({ duration: 1500 });
    }, [isLang]);

    if (isLoading) {
        return <Component.Loader />;
    }
    return (
        <div>
            <SliderHome  data={data?.sliders}/>
            <About data={data?.about_us}/>
            {/* <Services data={data?.statistics}/> */}
            <Component.BrandAboutUs  data={data?.why_us}/>
            <Brand data={data?.brands}/>
            <Component.BrandComponents data={data?.ingredients} />
            <ConnectHome />
            <Component.Footer />
        </div>
    );
};

export default Home;