import React from 'react'

const Facebook = ({ color }) => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 24 24" id="tiktok">
	<g transform="translate(-40 -40)">
		<rect width="24" height="24" x="40" y="40" fill="none">
		</rect>
		<path fill="#fff" d="M17.897,9.83L17.897,16.18C17.897,20.398 15.386,25.082 9.183,25.082C4.4,25.061 0.476,21.119 0.476,16.336C0.476,16.262 0.477,16.188 0.479,16.108C0.619,11.492 4.455,7.772 9.073,7.772C9.5,7.772 9.927,7.804 10.35,7.867C10.879,7.947 11.271,8.402 11.271,8.937L11.271,13.059C11.271,13.395 11.115,13.711 10.849,13.916C10.583,14.121 10.238,14.19 9.913,14.105C9.705,14.05 9.49,14.022 9.274,14.022C7.937,14.022 6.824,15.091 6.77,16.427L6.764,16.591C6.764,17.776 7.739,18.752 8.924,18.752L9.403,18.753C10.582,18.753 11.552,17.783 11.552,16.603L11.541,16.336L11.541,0C11.541,-0.597 12.026,-1.082 12.623,-1.082L16.7,-1.082C17.231,-1.082 17.684,-0.696 17.768,-0.172C18.061,1.652 18.617,2.797 19.451,3.513C20.292,4.235 21.38,4.513 22.611,4.71C23.136,4.794 23.522,5.247 23.522,5.778L23.522,9.913C23.522,10.215 23.395,10.504 23.173,10.708C22.951,10.913 22.653,11.016 22.352,10.991C20.806,10.864 19.299,10.47 17.897,9.83ZM15.809,1.082L13.705,1.082L13.705,16.298C13.712,16.399 13.716,16.501 13.716,16.603C13.716,18.969 11.769,20.916 9.403,20.916C9.32,20.916 9.237,20.914 9.154,20.908C9.078,20.913 9.001,20.915 8.924,20.915C6.552,20.915 4.601,18.963 4.601,16.591C4.601,16.499 4.604,16.406 4.608,16.339C4.707,13.907 6.692,11.948 9.107,11.862L9.107,9.936L9.073,9.935C5.617,9.935 2.746,12.72 2.641,16.169C2.64,16.224 2.639,16.28 2.639,16.336C2.639,19.936 5.593,22.903 9.188,22.918C13.864,22.918 15.733,19.362 15.733,16.18L15.733,8.013C15.733,7.618 15.949,7.254 16.296,7.064C16.643,6.874 17.065,6.889 17.398,7.102C18.609,7.877 19.954,8.411 21.358,8.677L21.358,6.674C20.082,6.392 18.962,5.945 18.042,5.155C17.048,4.301 16.259,3.042 15.809,1.082Z" transform="translate(42.016 42.03) scale(.83207)">
		</path>
	</g>
</svg>
        </>
    )
}

export default Facebook