import React from 'react'

const Linkedin = ({color}) => {
    return (
        <>

<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.35596 6.05C10.273 5.113 11.467 4.5 12.856 4.5C14.3146 4.5 15.7136 5.07946 16.745 6.11091C17.7765 7.14236 18.356 8.54131 18.356 10V17.5H16.356V10C16.356 9.07174 15.9872 8.1815 15.3308 7.52513C14.6745 6.86875 13.7842 6.5 12.856 6.5C11.9277 6.5 11.0375 6.86875 10.3811 7.52513C9.72471 8.1815 9.35596 9.07174 9.35596 10V17.5H7.35596V5H9.35596V6.05ZM2.35596 3C1.95813 3 1.5766 2.84196 1.2953 2.56066C1.01399 2.27936 0.855957 1.89782 0.855957 1.5C0.855957 1.10218 1.01399 0.720644 1.2953 0.43934C1.5766 0.158035 1.95813 0 2.35596 0C2.75378 0 3.13531 0.158035 3.41662 0.43934C3.69792 0.720644 3.85596 1.10218 3.85596 1.5C3.85596 1.89782 3.69792 2.27936 3.41662 2.56066C3.13531 2.84196 2.75378 3 2.35596 3ZM1.35596 5H3.35596V17.5H1.35596V5Z" fill={color}/>
</svg>


        </>
    )
}

export default Linkedin
